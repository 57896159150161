<template>
  <div class="overflow-x-hidden antialiased">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { SplashScreen } from '@capacitor/splash-screen';
const isApp = useIsApp();
const { initToken } = useToken();

await initToken();

onMounted(async () => {
  // Dinamically import openpanel

  if (isApp) {
    try {
      await SplashScreen.hide();
    } catch (error) {
      console.error(error);
    }
  }
});

useSeoMeta({
  title: "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
  ogTitle: "respostaCerta - O jeito rápido, eficaz e gratuito de aumentar sua nota no ENEM",
  description:
    "Estude gratuitamente com questões ajustadas ao seu nível, receba resoluções detalhadas e descubra sua nota com a precisão do TRI, assim como no ENEM",
  ogDescription:
    "Estude gratuitamente com questões ajustadas ao seu nível, receba resoluções detalhadas e descubra sua nota com a precisão do TRI, assim como no ENEM",
  ogImage: "https://respostacerta.com/og.png",
});
</script>
