import { default as ajudaQPvn8ISHdcMeta } from "/vercel/path0/apps/client/pages/ajuda.vue?macro=true";
import { default as assinarosNH2JZEeIMeta } from "/vercel/path0/apps/client/pages/assinar.vue?macro=true";
import { default as applejlZZ6KTHwoMeta } from "/vercel/path0/apps/client/pages/auth/apple.vue?macro=true";
import { default as googlepWASOygBVEMeta } from "/vercel/path0/apps/client/pages/auth/google.vue?macro=true";
import { default as cadastrarqJFvapAppzMeta } from "/vercel/path0/apps/client/pages/cadastrar.vue?macro=true";
import { default as comecarCU41Z3w7QMMeta } from "/vercel/path0/apps/client/pages/comecar.vue?macro=true";
import { default as downloadjh4pJhn8AIMeta } from "/vercel/path0/apps/client/pages/download.vue?macro=true";
import { default as entrarGydni10AzUMeta } from "/vercel/path0/apps/client/pages/entrar.vue?macro=true";
import { default as esqueci_45minha_45senhatp2vZEtY7PMeta } from "/vercel/path0/apps/client/pages/esqueci-minha-senha.vue?macro=true";
import { default as index3sh8z89WVFMeta } from "/vercel/path0/apps/client/pages/index.vue?macro=true";
import { default as inicio0QimGmF475Meta } from "/vercel/path0/apps/client/pages/inicio.vue?macro=true";
import { default as blocoTE2O3qv1XNMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/bloco.vue?macro=true";
import { default as historicohkCcXeCsKYMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/index/historico.vue?macro=true";
import { default as index9N7gjpOL9aMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/index/index.vue?macro=true";
import { default as simuladosBxuuyFbFsUMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/index/simulados.vue?macro=true";
import { default as indexCmDjfhSsL5Meta } from "/vercel/path0/apps/client/pages/materia/[subject]/index.vue?macro=true";
import { default as indexscyudh9GquMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/simulado/[testId]/index.vue?macro=true";
import { default as relatoriomomV7iywaeMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/simulado/[testId]/relatorio.vue?macro=true";
import { default as revisaoNR7ckdKAuaMeta } from "/vercel/path0/apps/client/pages/materia/[subject]/simulado/[testId]/revisao.vue?macro=true";
import { default as assinaturayLaBqtJsdMMeta } from "/vercel/path0/apps/client/pages/minha-conta/assinatura.vue?macro=true";
import { default as deletarEg0NzqKjgAMeta } from "/vercel/path0/apps/client/pages/minha-conta/deletar.vue?macro=true";
import { default as index73yEVYZ2JDMeta } from "/vercel/path0/apps/client/pages/minha-conta/index.vue?macro=true";
import { default as senhaLp41UtBXYdMeta } from "/vercel/path0/apps/client/pages/minha-conta/senha.vue?macro=true";
import { default as minha_45contaC5nRlwebHmMeta } from "/vercel/path0/apps/client/pages/minha-conta.vue?macro=true";
import { default as onboardingmu1Or0yMhiMeta } from "/vercel/path0/apps/client/pages/onboarding.vue?macro=true";
import { default as notificacoesmL11JmCym3Meta } from "/vercel/path0/apps/client/pages/preferencias/notificacoes.vue?macro=true";
import { default as privacidadepQjM0DdyshMeta } from "/vercel/path0/apps/client/pages/privacidade.vue?macro=true";
import { default as _91id_93Floo5nXwh2Meta } from "/vercel/path0/apps/client/pages/questoes/[id].vue?macro=true";
import { default as sairvbAL15Uf6IMeta } from "/vercel/path0/apps/client/pages/sair.vue?macro=true";
import { default as simulado_45enem_45gratuitoiDtcGyRAfhMeta } from "/vercel/path0/apps/client/pages/simulado-enem-gratuito.vue?macro=true";
import { default as sucessoMA1aLvmMgMMeta } from "/vercel/path0/apps/client/pages/sucesso.vue?macro=true";
import { default as termos_45de_45usoCfjdALbBptMeta } from "/vercel/path0/apps/client/pages/termos-de-uso.vue?macro=true";
import { default as test9gCbTQjiXWMeta } from "/vercel/path0/apps/client/pages/test.vue?macro=true";
export default [
  {
    name: ajudaQPvn8ISHdcMeta?.name ?? "ajuda",
    path: ajudaQPvn8ISHdcMeta?.path ?? "/ajuda",
    meta: ajudaQPvn8ISHdcMeta || {},
    alias: ajudaQPvn8ISHdcMeta?.alias || [],
    redirect: ajudaQPvn8ISHdcMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/ajuda.vue").then(m => m.default || m)
  },
  {
    name: assinarosNH2JZEeIMeta?.name ?? "assinar",
    path: assinarosNH2JZEeIMeta?.path ?? "/assinar",
    meta: assinarosNH2JZEeIMeta || {},
    alias: assinarosNH2JZEeIMeta?.alias || [],
    redirect: assinarosNH2JZEeIMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/assinar.vue").then(m => m.default || m)
  },
  {
    name: applejlZZ6KTHwoMeta?.name ?? "auth-apple",
    path: applejlZZ6KTHwoMeta?.path ?? "/auth/apple",
    meta: applejlZZ6KTHwoMeta || {},
    alias: applejlZZ6KTHwoMeta?.alias || [],
    redirect: applejlZZ6KTHwoMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/auth/apple.vue").then(m => m.default || m)
  },
  {
    name: googlepWASOygBVEMeta?.name ?? "auth-google",
    path: googlepWASOygBVEMeta?.path ?? "/auth/google",
    meta: googlepWASOygBVEMeta || {},
    alias: googlepWASOygBVEMeta?.alias || [],
    redirect: googlepWASOygBVEMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/auth/google.vue").then(m => m.default || m)
  },
  {
    name: cadastrarqJFvapAppzMeta?.name ?? "cadastrar",
    path: cadastrarqJFvapAppzMeta?.path ?? "/cadastrar",
    meta: cadastrarqJFvapAppzMeta || {},
    alias: cadastrarqJFvapAppzMeta?.alias || [],
    redirect: cadastrarqJFvapAppzMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/cadastrar.vue").then(m => m.default || m)
  },
  {
    name: comecarCU41Z3w7QMMeta?.name ?? "comecar",
    path: comecarCU41Z3w7QMMeta?.path ?? "/comecar",
    meta: comecarCU41Z3w7QMMeta || {},
    alias: comecarCU41Z3w7QMMeta?.alias || [],
    redirect: comecarCU41Z3w7QMMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/comecar.vue").then(m => m.default || m)
  },
  {
    name: downloadjh4pJhn8AIMeta?.name ?? "download",
    path: downloadjh4pJhn8AIMeta?.path ?? "/download",
    meta: downloadjh4pJhn8AIMeta || {},
    alias: downloadjh4pJhn8AIMeta?.alias || [],
    redirect: downloadjh4pJhn8AIMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/download.vue").then(m => m.default || m)
  },
  {
    name: entrarGydni10AzUMeta?.name ?? "entrar",
    path: entrarGydni10AzUMeta?.path ?? "/entrar",
    meta: entrarGydni10AzUMeta || {},
    alias: entrarGydni10AzUMeta?.alias || [],
    redirect: entrarGydni10AzUMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/entrar.vue").then(m => m.default || m)
  },
  {
    name: esqueci_45minha_45senhatp2vZEtY7PMeta?.name ?? "esqueci-minha-senha",
    path: esqueci_45minha_45senhatp2vZEtY7PMeta?.path ?? "/esqueci-minha-senha",
    meta: esqueci_45minha_45senhatp2vZEtY7PMeta || {},
    alias: esqueci_45minha_45senhatp2vZEtY7PMeta?.alias || [],
    redirect: esqueci_45minha_45senhatp2vZEtY7PMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/esqueci-minha-senha.vue").then(m => m.default || m)
  },
  {
    name: index3sh8z89WVFMeta?.name ?? "index",
    path: index3sh8z89WVFMeta?.path ?? "/",
    meta: index3sh8z89WVFMeta || {},
    alias: index3sh8z89WVFMeta?.alias || [],
    redirect: index3sh8z89WVFMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inicio0QimGmF475Meta?.name ?? "inicio",
    path: inicio0QimGmF475Meta?.path ?? "/inicio",
    meta: inicio0QimGmF475Meta || {},
    alias: inicio0QimGmF475Meta?.alias || [],
    redirect: inicio0QimGmF475Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/inicio.vue").then(m => m.default || m)
  },
  {
    name: blocoTE2O3qv1XNMeta?.name ?? "materia-subject-bloco",
    path: blocoTE2O3qv1XNMeta?.path ?? "/materia/:subject()/bloco",
    meta: blocoTE2O3qv1XNMeta || {},
    alias: blocoTE2O3qv1XNMeta?.alias || [],
    redirect: blocoTE2O3qv1XNMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/bloco.vue").then(m => m.default || m)
  },
  {
    name: indexCmDjfhSsL5Meta?.name ?? undefined,
    path: indexCmDjfhSsL5Meta?.path ?? "/materia/:subject()",
    meta: indexCmDjfhSsL5Meta || {},
    alias: indexCmDjfhSsL5Meta?.alias || [],
    redirect: indexCmDjfhSsL5Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index.vue").then(m => m.default || m),
    children: [
  {
    name: historicohkCcXeCsKYMeta?.name ?? "materia-subject-index-historico",
    path: historicohkCcXeCsKYMeta?.path ?? "historico",
    meta: historicohkCcXeCsKYMeta || {},
    alias: historicohkCcXeCsKYMeta?.alias || [],
    redirect: historicohkCcXeCsKYMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index/historico.vue").then(m => m.default || m)
  },
  {
    name: index9N7gjpOL9aMeta?.name ?? "materia-subject-index",
    path: index9N7gjpOL9aMeta?.path ?? "",
    meta: index9N7gjpOL9aMeta || {},
    alias: index9N7gjpOL9aMeta?.alias || [],
    redirect: index9N7gjpOL9aMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index/index.vue").then(m => m.default || m)
  },
  {
    name: simuladosBxuuyFbFsUMeta?.name ?? "materia-subject-index-simulados",
    path: simuladosBxuuyFbFsUMeta?.path ?? "simulados",
    meta: simuladosBxuuyFbFsUMeta || {},
    alias: simuladosBxuuyFbFsUMeta?.alias || [],
    redirect: simuladosBxuuyFbFsUMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/index/simulados.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexscyudh9GquMeta?.name ?? "materia-subject-simulado-testId",
    path: indexscyudh9GquMeta?.path ?? "/materia/:subject()/simulado/:testId()",
    meta: indexscyudh9GquMeta || {},
    alias: indexscyudh9GquMeta?.alias || [],
    redirect: indexscyudh9GquMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/simulado/[testId]/index.vue").then(m => m.default || m)
  },
  {
    name: relatoriomomV7iywaeMeta?.name ?? "materia-subject-simulado-testId-relatorio",
    path: relatoriomomV7iywaeMeta?.path ?? "/materia/:subject()/simulado/:testId()/relatorio",
    meta: relatoriomomV7iywaeMeta || {},
    alias: relatoriomomV7iywaeMeta?.alias || [],
    redirect: relatoriomomV7iywaeMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/simulado/[testId]/relatorio.vue").then(m => m.default || m)
  },
  {
    name: revisaoNR7ckdKAuaMeta?.name ?? "materia-subject-simulado-testId-revisao",
    path: revisaoNR7ckdKAuaMeta?.path ?? "/materia/:subject()/simulado/:testId()/revisao",
    meta: revisaoNR7ckdKAuaMeta || {},
    alias: revisaoNR7ckdKAuaMeta?.alias || [],
    redirect: revisaoNR7ckdKAuaMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/materia/[subject]/simulado/[testId]/revisao.vue").then(m => m.default || m)
  },
  {
    name: minha_45contaC5nRlwebHmMeta?.name ?? undefined,
    path: minha_45contaC5nRlwebHmMeta?.path ?? "/minha-conta",
    meta: minha_45contaC5nRlwebHmMeta || {},
    alias: minha_45contaC5nRlwebHmMeta?.alias || [],
    redirect: minha_45contaC5nRlwebHmMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta.vue").then(m => m.default || m),
    children: [
  {
    name: assinaturayLaBqtJsdMMeta?.name ?? "minha-conta-assinatura",
    path: assinaturayLaBqtJsdMMeta?.path ?? "assinatura",
    meta: assinaturayLaBqtJsdMMeta || {},
    alias: assinaturayLaBqtJsdMMeta?.alias || [],
    redirect: assinaturayLaBqtJsdMMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/assinatura.vue").then(m => m.default || m)
  },
  {
    name: deletarEg0NzqKjgAMeta?.name ?? "minha-conta-deletar",
    path: deletarEg0NzqKjgAMeta?.path ?? "deletar",
    meta: deletarEg0NzqKjgAMeta || {},
    alias: deletarEg0NzqKjgAMeta?.alias || [],
    redirect: deletarEg0NzqKjgAMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/deletar.vue").then(m => m.default || m)
  },
  {
    name: index73yEVYZ2JDMeta?.name ?? "minha-conta",
    path: index73yEVYZ2JDMeta?.path ?? "",
    meta: index73yEVYZ2JDMeta || {},
    alias: index73yEVYZ2JDMeta?.alias || [],
    redirect: index73yEVYZ2JDMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/index.vue").then(m => m.default || m)
  },
  {
    name: senhaLp41UtBXYdMeta?.name ?? "minha-conta-senha",
    path: senhaLp41UtBXYdMeta?.path ?? "senha",
    meta: senhaLp41UtBXYdMeta || {},
    alias: senhaLp41UtBXYdMeta?.alias || [],
    redirect: senhaLp41UtBXYdMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/minha-conta/senha.vue").then(m => m.default || m)
  }
]
  },
  {
    name: onboardingmu1Or0yMhiMeta?.name ?? "onboarding",
    path: onboardingmu1Or0yMhiMeta?.path ?? "/onboarding",
    meta: onboardingmu1Or0yMhiMeta || {},
    alias: onboardingmu1Or0yMhiMeta?.alias || [],
    redirect: onboardingmu1Or0yMhiMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: notificacoesmL11JmCym3Meta?.name ?? "preferencias-notificacoes",
    path: notificacoesmL11JmCym3Meta?.path ?? "/preferencias/notificacoes",
    meta: notificacoesmL11JmCym3Meta || {},
    alias: notificacoesmL11JmCym3Meta?.alias || [],
    redirect: notificacoesmL11JmCym3Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/preferencias/notificacoes.vue").then(m => m.default || m)
  },
  {
    name: privacidadepQjM0DdyshMeta?.name ?? "privacidade",
    path: privacidadepQjM0DdyshMeta?.path ?? "/privacidade",
    meta: privacidadepQjM0DdyshMeta || {},
    alias: privacidadepQjM0DdyshMeta?.alias || [],
    redirect: privacidadepQjM0DdyshMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/privacidade.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Floo5nXwh2Meta?.name ?? "questoes-id",
    path: _91id_93Floo5nXwh2Meta?.path ?? "/questoes/:id()",
    meta: _91id_93Floo5nXwh2Meta || {},
    alias: _91id_93Floo5nXwh2Meta?.alias || [],
    redirect: _91id_93Floo5nXwh2Meta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/questoes/[id].vue").then(m => m.default || m)
  },
  {
    name: sairvbAL15Uf6IMeta?.name ?? "sair",
    path: sairvbAL15Uf6IMeta?.path ?? "/sair",
    meta: sairvbAL15Uf6IMeta || {},
    alias: sairvbAL15Uf6IMeta?.alias || [],
    redirect: sairvbAL15Uf6IMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/sair.vue").then(m => m.default || m)
  },
  {
    name: simulado_45enem_45gratuitoiDtcGyRAfhMeta?.name ?? "simulado-enem-gratuito",
    path: simulado_45enem_45gratuitoiDtcGyRAfhMeta?.path ?? "/simulado-enem-gratuito",
    meta: simulado_45enem_45gratuitoiDtcGyRAfhMeta || {},
    alias: simulado_45enem_45gratuitoiDtcGyRAfhMeta?.alias || [],
    redirect: simulado_45enem_45gratuitoiDtcGyRAfhMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/simulado-enem-gratuito.vue").then(m => m.default || m)
  },
  {
    name: sucessoMA1aLvmMgMMeta?.name ?? "sucesso",
    path: sucessoMA1aLvmMgMMeta?.path ?? "/sucesso",
    meta: sucessoMA1aLvmMgMMeta || {},
    alias: sucessoMA1aLvmMgMMeta?.alias || [],
    redirect: sucessoMA1aLvmMgMMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/sucesso.vue").then(m => m.default || m)
  },
  {
    name: termos_45de_45usoCfjdALbBptMeta?.name ?? "termos-de-uso",
    path: termos_45de_45usoCfjdALbBptMeta?.path ?? "/termos-de-uso",
    meta: termos_45de_45usoCfjdALbBptMeta || {},
    alias: termos_45de_45usoCfjdALbBptMeta?.alias || [],
    redirect: termos_45de_45usoCfjdALbBptMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/termos-de-uso.vue").then(m => m.default || m)
  },
  {
    name: test9gCbTQjiXWMeta?.name ?? "test",
    path: test9gCbTQjiXWMeta?.path ?? "/test",
    meta: test9gCbTQjiXWMeta || {},
    alias: test9gCbTQjiXWMeta?.alias || [],
    redirect: test9gCbTQjiXWMeta?.redirect,
    component: () => import("/vercel/path0/apps/client/pages/test.vue").then(m => m.default || m)
  }
]