import { useStorage } from '@vueuse/core'
import { Preferences } from '@capacitor/preferences';

const token = ref<string | null>(null)

export function useToken() {
  const config = useRuntimeConfig();
  const cookieToken = useCookie<string | null>("token", {
    maxAge: 31536000,
    default: () => ''
  });

  const storageToken = useStorage("token", "");

  const initToken = async () => {
    if (config.public.isInAppMode) {
      // Patch storageToken for old users
      if (storageToken.value) {
        token.value = storageToken.value;
        await Preferences.set({ key: 'token', value: storageToken.value });
        storageToken.value = null;
        return;
      }

      const { value } = await Preferences.get({ key: 'token' });

      token.value = value ?? null;
    } else {
      token.value = cookieToken.value;
    }

    return token.value;
  }

  const setToken = async (value: string | null) => {
    if (config.public.isInAppMode) {
      storageToken.value = value;
      await Preferences.set({ key: 'token', value: value ?? '' });
    } else {
      cookieToken.value = value;
    }

    token.value = value;
  }

  return { token, initToken, setToken };
}
